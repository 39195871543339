<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<div class="grid">
   <div class="col-8">
       <app-brand-admin-header></app-brand-admin-header>
   </div>
   <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded"  [disabled]="isDetailsShown" *ngIf="isBrandSelectionButtonShown">
        </button>
    </div>  
</div> 

<!-- A C T I V E    A C C O U N T S    S T A T S -->

<div class="col-12" [hidden]="isDashboardHidden">

    <div class="col-6">
    
        <p-card header="Active Accounts" subheader="" styleClass="ui-card-light_sized">

            <p-table #dt [columns]="activeAccountsCols" [value]="enrollments"  tableStyleClass="prime-table">
                <ng-template pTemplate="caption">
                    Enrollments by Unique Accounts
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> 

        </p-card>            

    </div>

</div>

<!-- C S R    S T A T S -->

<div class="col-12" [hidden]="isDashboardHidden">

    <div class="col-6">

        <p-card header="CSR Statistics" subheader="" styleClass="ui-card-light_sized">

            <p-table #dtt [columns]="csrStatsCols" [value]="csrStats" tableStyleClass="prime-table">
                <ng-template pTemplate="caption">
                    CSR Sends
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{col.header}}
                    </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> 

        </p-card> 

    </div>

</div>

<div class="col-1" id="idSpinner" [hidden]="isSpinnerHidden">
    <div class="loader"></div>  
</div>


<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="client-dashboard"></p-confirmDialog>
