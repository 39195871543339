import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldGwAccountLabel extends OfferFieldBase {

    fieldAlignmentOptions: any[];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        fieldAlignmentOptions: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.fieldAlignmentOptions = fieldAlignmentOptions;
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Label(N), Field Value, Field Alignment (N), Show/hide Field Expression(N)

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldLabel',
            label: 'Field Label',
            value: this.data.fieldLabel,
            styleWidth: '25em',
            required: false,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'positionSpec',
            label: 'Position Spec',
            value: this.data.positionSpec,
            styleWidth: '40em',
            required: false,
            tooltip: 'Position specification for Google and/or Samsung devices',
            order: 6
        }));
        
        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldAlignment',
            label: 'Field Alignment',
            value: this.data.fieldAlignment,
            styleWidth: '15em',
            required: false,
            tooltip: 'Select the proper field allignment on the pass',
            options: this.fieldAlignmentOptions,
            order: 7
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 8
        }));
        
        this.offerFieldAttributes.sort((a, b) => a.order - b.order);

    }

}


