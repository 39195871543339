import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IBillerBrand } from '../../interfaces/biller-brand';
import { IEnvRegion } from '../../interfaces/env-region';
import { BrandService } from '../../services/config/brand.service';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { BrandConfigPropertyService } from '../../services/config/brand-config-property.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
// import { Brand } from 'src/app/models/brand';

const BRAND_CODE_ATTRIBUTE = 'brandCode';

@Component({
  selector: 'app-choose-brand',
  templateUrl: './choose-brand.component.html',
  styleUrls: ['./choose-brand.component.css']
})
export class ChooseBrandComponent implements OnInit {

  @Input() displayBrandDialog?: boolean;
  @Output() chooseBrandEvent = new EventEmitter<IBrand>();
  
  isBrandGranted: boolean = true;
  noBrandWarning?: string;
  brandCols: any[] = [];
  brands: IBillerBrand[] = [];
  envRegions?: IEnvRegion[];
  selectedBrand: IBrand = <IBrand> {};
  envRegionBaseUrl?: string;
 
  isSpinnerHidden: boolean = true;

  constructor(
    private brandService: BrandService,
    private tokenStorageService: TokenStorageService,
    private userContextService: UserContextService,
    private brandProfileService: BrandProfileService,
    private brandConfigPropertyService: BrandConfigPropertyService,
    private confirmationService: ConfirmationService
    ) { }

  ngOnInit(): void {
    this.isBrandGranted = false;
    // Define the columns for Brands grid
    this.brandCols = [
      { field: 'brandCode', header: 'Brand Code', display: 'table-cell', width:'20%' },
      { field: 'brandName', header: 'Brand Name', display: 'table-cell', width:'35%' },
      { field: 'envCode', header: 'Env. Region', display: 'table-cell', width:'20%' },
      { field: 'createDate', header: 'Create Date', display: 'table-cell', width:'25%' }
    ]; 

    // Get current user's brand level authentication
    var hasAllBrandsAttribute = this.userContextService.hasAllBrands();
    var grantedBrands = this.userContextService.getUserBrands();
    // Retrieve all brands and environments
    this.getBrandCodes(hasAllBrandsAttribute, grantedBrands);   
    // this.getEnvRegions();
  }

  // Retrieve distinct brand codes
  getBrandCodes(hasAllBrandsAttribute: boolean, grantedBrands: string[]): void {
    // console.log('Before making Web API call',Date.now());
    this.isSpinnerHidden = false;
    this.noBrandWarning = "";
    this.brandService.getBrands()
    .subscribe(billerBrands => {
      // console.log('After making Web API call',Date.now());
      if (hasAllBrandsAttribute) {
        this.brands = billerBrands;
        this.isBrandGranted = true;
      } else {
        billerBrands.forEach(billerBrand => {
          var index = grantedBrands.findIndex(brandCode => brandCode == billerBrand.brandCode);
            if (index >= 0) {
              this.brands.push(billerBrand);
              this.isBrandGranted = true;
            }
          }
        )
      }
      if(this.isBrandGranted) {
        this.brands.sort((a, b) => (a.brandCode >= b.brandCode) ? 1 : -1)
      } else {
        this.noBrandWarning = "No single brand has been linked up to your account.  Please contact support@walletron.com.";
      } 
      this.isSpinnerHidden = true;
      // console.log('After sorting',Date.now());
    });
  }  

  // // Retrieve all environmental regions
  // getEnvRegions(): void {
  //   // console.log('chooseBrand: ', 'inside getEnvRegions');
  //   this.envRegionService.getEnvRegions()
  //     .subscribe(envRegions => {
  //       // console.log('chooseBrand: ', 'results of getEnvRegions');
  //       this.envRegions = envRegions;
  //     });
  // } 

  // When the user chooses a brand
  onRowBrandSelect(event: any) {
    this.selectedBrand = <IBrand> {
      brandCode: event.data.brandCode,
      brandName: event.data.brandName,
      environment: [event.data.envCode],
      brandUuid: event.data.brandUuid,
      baseUrl: event.data.baseUrl
    }
    // Get the logo image of the brand
    this.brandProfileService.getBrandLogoImage (this.selectedBrand.brandCode || '', this.selectedBrand.baseUrl || '')
    .subscribe(result => {
      if (result) {
        this.selectedBrand.logoImageContent = result.imageContent;
        this.selectedBrand.logoImageType = result.imageType;
      }
    });
    // Figure out whether the chosen brand is Samsung platform enabled
    this.brandConfigPropertyService.getBrandConfigPropertyByName(
      this.selectedBrand.brandCode || '', "BRAND_IsSamsungPlatformEnabled", this.selectedBrand.baseUrl || '')
    .subscribe({
      next: (response) => {
        this.selectedBrand.isSamsungPlatformEnabled = response.propertyValue.toLowerCase() == "true";
      },
      error: (error) => {
        this.selectedBrand.isSamsungPlatformEnabled = false;
        console.log('onRowBrandSelect', error);
      },
      complete: () => {
      }
    });

  } 

  // When the user chooses a brand
  onBrandSelect() {
    if (this.selectedBrand.brandCode) {
      this.chooseBrandEvent.emit(this.selectedBrand);
    } else {
//      this.chooseBrandEvent.emit(null);
      this.confirmationService.confirm({
        message: "No brand has been selected.  Please chose a brand from the list first, then click on Select button again.",
        header: 'Warning',
        key: 'choose-brand',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
          accept: () => {}
      });
    }
  }  

  // When the user cancels the dialog to select a brand
  onCancelBrandSelect() {
    this.chooseBrandEvent.emit();
  }

}
