import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { SharedService } from "../../services/shared.service";
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { BrandService } from '../../services/config/brand.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { IBrand } from '../../interfaces/brand';
import { IBrandProfile }  from '../../interfaces/brand-profile';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-brand-profile',
  templateUrl: './brand-profile.component.html',
  styleUrls: ['./brand-profile.component.css']
})
export class BrandProfileComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "BrandProfile";
  titleMessage = "Brand Profile";
  currentBrand: IBrand;
  currentBrandProfile!: IBrandProfile;
  openBrandSelectionDialog!: boolean;
  isCurrentBrandShown: boolean = true;
  isSubmitShown: boolean = false;
  isImagePreviewShown: boolean = false;
  image: any;
  brandImages: any[] = [];
  imageList: any[] = [];
  paymentsPlatforms: any[] = [];

  brandProfileForm!: FormGroup;
  isSpinnerHidden: boolean = true;

  isDeactivateButtonShown: boolean = false;
  isActivateButtonShown: boolean = false;
  isActiveDisabled: boolean = true;
  isBrandActive: boolean = true;

  autoResize: boolean = true;
  isDisabled: boolean = false;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,    
    private brandService: BrandService, 
    private sharedService: SharedService,
    private userContextService: UserContextService,    
    private confirmationService: ConfirmationService)
    {
        this.currentBrand = this.sharedService.getDummyBrand();
    }

  ngOnInit(): void {
    this.brandProfileForm = this.fb.group({
      'brandId':  new FormControl(null, [Validators.required]),
      'brandCode':  new FormControl('', [Validators.required, Validators.maxLength(3)]),
      'brandName': new FormControl('', [Validators.maxLength(45)]),
      'description': new FormControl('', [Validators.maxLength(1000)]),
      'showNonMobileLogo':  new FormControl(true, [Validators.required]),
      'showNonMobileEmail':  new FormControl(true, [Validators.required]),
      'showNonMobileSMS':  new FormControl(true, [Validators.required]),
      'showNonMobileQR':  new FormControl(true, [Validators.required]),
      'nonmobileEmail': new FormControl('', [Validators.maxLength(1000)]),
      'nonmobileHeader': new FormControl('', [Validators.maxLength(1000)]),
      'nonmobileSMS': new FormControl('', [Validators.maxLength(1000)]),
      'nonmobileQR': new FormControl('', [Validators.maxLength(1000)]),
      'nonmobileFooter': new FormControl('', [Validators.maxLength(1000)]),
      'senderName': new FormControl('', [Validators.maxLength(45)]),
      'senderEmail': new FormControl('', [Validators.maxLength(45)]),
      'passTypeIdentifier': new FormControl('', [Validators.maxLength(100)]),
      'keyName': new FormControl('', [Validators.required, Validators.maxLength(40)]),
      'keyFile': new FormControl('', [Validators.required, Validators.maxLength(4)]),
      // 'pmtInstruct': new FormControl('', [Validators.maxLength(1000)]),
      // 'pmtFooter': new FormControl('', [Validators.maxLength(1000)]),
      // 'pmtThanks': new FormControl('', [Validators.maxLength(1000)]),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
      'authToken': new FormControl('', [Validators.required, Validators.maxLength(36)]),
      'clientName': new FormControl('', [Validators.maxLength(45)]),
      'gwIssuerId': new FormControl('', [Validators.maxLength(45)]),
      'imageUid': new FormControl(null),
      'nonmobileLogoImageUid': new FormControl(null),
      'active':  new FormControl({value: true, disabled: true}, [Validators.required]),
      'paymentsPlatform': new FormControl('', [Validators.maxLength(60)]),
      'allowedIPAddresses': new FormControl(''),
    });  
    this.paymentsPlatforms = [
      {label: "", value: null}, 
      {label: "Speedpay NextGen", value: "Speedpay NextGen"}, 
      {label: "Speedpay One", value: "Speedpay One"}, 
      {label: "V4", value: "V4"}, 
      {label: "Other", value: "Other"}
    ];  
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.getBrandImages(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      // this.getBrandProperties(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }

    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY')) {
      this.isSubmitShown = true;
    }
      
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.sharedService.setCurrentBrand(item);    
      this.getBrandImages(item.brandCode, item.baseUrl);
      // this.getBrandProperties(item.brandCode, item.baseUrl);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY')) {
        this.isSubmitShown = true;
      }   
    }
  }

  getBrandProfile(brandCode: string, baseUrl: string): void {
    this.isSpinnerHidden = false;
    this.brandProfileService.getBrandProfile(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        this.setFormElements(response);
        if (response.active) {
          this.isDeactivateButtonShown = true;
          this.isActivateButtonShown = false;
        } else {
          this.isDeactivateButtonShown = false;
          this.isActivateButtonShown = true;
        }
        this.isSpinnerHidden = true;
      },
      error: (error) => {
        this.alertTheUser(error.message);
        this.isSpinnerHidden = true;
      },
      complete: () => {
      }
    });
  }


  // Find brand attributes located on the Postres side
  private getOtherBrandAttributes(brandCode: string) {
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe (brand => {
      console.log('getOtherBrandAttributes', brand);
      this.brandProfileForm.patchValue({
        paymentsPlatform: brand.hasOwnProperty('paymentsPlatform') ? brand.paymentsPlatform : null,
        allowedIPAddresses: brand.hasOwnProperty('allowedIPAddresses') ? brand.allowedIPAddresses : null
      })
    });
  }

  // Select all existing brand images
  getBrandImages(brandCode: string, baseUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandImages(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        this.brandImages = response;
        this.imageList.length = 0;
        this.imageList.push({label: '[none]', value: null});
        if (response) {
          response.forEach(element => {
            this.imageList.push({label: element.imageName, value: element.imageUID});
          });
        }
        this.getBrandProfile(brandCode, baseUrl);
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }  

  setFormElements(response: IBrandProfile) {
    this.currentBrandProfile = response;
    console.log('setFormElements', response);
    this.isBrandActive = response.active;
    this.brandProfileForm.setValue({
      brandId: response.hasOwnProperty('brandID') ? response.brandID : null,
      brandCode: response.hasOwnProperty('brandCode') ? response.brandCode : null,
      brandName: response.hasOwnProperty('brandName') ? response.brandName : null,
      description: response.hasOwnProperty('description') ? response.description : null,
      showNonMobileLogo: response.hasOwnProperty('showNonMobileLogo') ? response.showNonMobileLogo : null,
      showNonMobileEmail: response.hasOwnProperty('showNonMobileEmail') ? response.showNonMobileEmail : null,
      showNonMobileSMS: response.hasOwnProperty('showNonMobileSMS') ? response.showNonMobileSMS : null,
      showNonMobileQR: response.hasOwnProperty('showNonMobileQR') ? response.showNonMobileQR : null,
      nonmobileEmail: response.hasOwnProperty('nonmobileEmail') ? response.nonmobileEmail : null,
      nonmobileHeader: response.hasOwnProperty('nonmobileHeader') ? response.nonmobileHeader : null,
      nonmobileSMS: response.hasOwnProperty('nonmobileSMS') ? response.nonmobileSMS : null,
      nonmobileQR: response.hasOwnProperty('nonmobileQR') ? response.nonmobileQR : null,
      nonmobileFooter: response.hasOwnProperty('nonmobileFooter') ? response.nonmobileFooter : null,
      senderName: response.hasOwnProperty('senderName') ? response.senderName : null,
      senderEmail: response.hasOwnProperty('senderEmail') ? response.senderEmail : null,
      passTypeIdentifier: response.hasOwnProperty('passTypeIdentifier') ? response.passTypeIdentifier : null,
      keyName: response.hasOwnProperty('keyName') ? response.keyName : null,
      keyFile: response.hasOwnProperty('keyFile') ? response.keyFile : null,
      // pmtInstruct: response.hasOwnProperty('pmtInstruct') ? response.pmtInstruct : null,
      // pmtFooter: response.hasOwnProperty('pmtFooter') ? response.pmtFooter : null,
      // pmtThanks: response.hasOwnProperty('pmtThanks') ? response.pmtThanks : null,
      createDate: response.hasOwnProperty('createDate') ? this.sharedService.formatFromEpoch(response.createDate) : null,
      lastUpdateDate: response.hasOwnProperty('lastUpdateDate') ? this.sharedService.formatFromEpoch(response.lastUpdateDate) : null,
      authToken: response.hasOwnProperty('authToken') ? response.authToken : null,
      clientName: response.client.hasOwnProperty('clientName') ? response.client.clientName : null,
      gwIssuerId: response.client.hasOwnProperty('gwIssuerId') ? response.client.gwIssuerId : null,
      imageUid: response.hasOwnProperty('imageUID') ? response.imageUID : null,
      nonmobileLogoImageUid: response.hasOwnProperty('nonmobileLogoImageUID') ? response.nonmobileLogoImageUID : null,
      active: response.hasOwnProperty('active') ? response.active : null,
      paymentsPlatform: null,
      allowedIPAddresses: null
    })
    this.getOtherBrandAttributes(response.brandCode);
  }

  onSubmit(event: any) {
    this.confirmationService.confirm({
      message: "Please confirm your submission of the Brand Profile modifications for '"
      + this.currentBrand.brandCode + "' (" + this.currentBrand.brandName + ").",
      header: 'Warning',
      key: 'brand-profile',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
          this.isSpinnerHidden = false;
          this.mergeBillerUpdates();
          this.brandProfileService.modifyBrandProfile(this.currentBrand.brandCode, this.currentBrand.baseUrl,
             this.currentBrandProfile)
             .subscribe({
              next: (response) => {
                this.submitOtherAttributes();
                this.setFormElements(response);
                // this.isSubmitShown = false;
                // this.isSpinnerHidden = true;
                // this.alertTheUser("Successfully modified Brand Profile for '"
                //   + this.currentBrand.brandCode + "' (" + this.currentBrand.brandName + ").");
                },
              error: (error) => {
                this.alertTheUser(error.message);
                this.isSpinnerHidden = true;
                  },
              complete: () => {
              }
            });
      }
    });
  }

  submitOtherAttributes() {
    this.isSpinnerHidden = false;
    let brand: IBrand = {
      brandCode: this.currentBrand.brandCode,
      baseUrl: this.currentBrand.baseUrl,
      brandName: this.brandProfileForm.value.brandName,
      paymentsPlatform: this.brandProfileForm.value.paymentsPlatform,
      allowedIPAddresses: this.brandProfileForm.value.allowedIPAddresses
    }
    this.brandService.modifyBrand(brand)
        .subscribe({
        next: (response) => {
          this.getOtherBrandAttributes(this.currentBrand.brandCode);
          this.isSubmitShown = false;
          this.isSpinnerHidden = true;
          this.alertTheUser("Successfully modified Brand Profile for '"
            + this.currentBrand.brandCode + "' (" + this.currentBrand.brandName + ").");
          },
        error: (error) => {
          this.alertTheUser(error.message);
          this.isSpinnerHidden = true;
            },
        complete: () => {
        }
      });
  }


  mergeBillerUpdates() {
    this.currentBrandProfile.brandName = this.brandProfileForm.value.brandName;
    this.currentBrandProfile.description = this.brandProfileForm.value.description;
    this.currentBrandProfile.showNonMobileLogo = this.brandProfileForm.value.showNonMobileLogo;
    this.currentBrandProfile.showNonMobileEmail = this.brandProfileForm.value.showNonMobileEmail;
    this.currentBrandProfile.showNonMobileSMS = this.brandProfileForm.value.showNonMobileSMS;
    this.currentBrandProfile.showNonMobileQR = this.brandProfileForm.value.showNonMobileQR;
    this.currentBrandProfile.nonmobileEmail = this.brandProfileForm.value.nonmobileEmail;
    this.currentBrandProfile.nonmobileHeader = this.brandProfileForm.value.nonmobileHeader;
    this.currentBrandProfile.nonmobileSMS = this.brandProfileForm.value.nonmobileSMS;
    this.currentBrandProfile.nonmobileQR = this.brandProfileForm.value.nonmobileQR;
    this.currentBrandProfile.nonmobileFooter = this.brandProfileForm.value.nonmobileFooter;
    this.currentBrandProfile.senderName = this.brandProfileForm.value.senderName;
    this.currentBrandProfile.senderEmail = this.brandProfileForm.value.senderEmail;
    // this.currentBrandProfile.pmtInstruct = this.brandProfileForm.value.pmtInstruct;
    // this.currentBrandProfile.pmtFooter = this.brandProfileForm.value.pmtFooter;
    // this.currentBrandProfile.pmtThanks = this.brandProfileForm.value.pmtThanks;
    this.currentBrandProfile.authToken = this.brandProfileForm.value.authToken;
    this.currentBrandProfile.client.clientName = this.brandProfileForm.value.clientName;
    this.currentBrandProfile.client.gwIssuerId = this.brandProfileForm.value.gwIssuerId;
    this.currentBrandProfile.imageUID = this.brandProfileForm.value.imageUid;
    this.currentBrandProfile.nonmobileLogoImageUID = this.brandProfileForm.value.nonmobileLogoImageUid;
    // this.currentBrandProfile.paymentsPlatform = this.brandProfileForm.value.paymentsPlatform;
    // this.currentBrandProfile.allowedIPAddresses = this.brandProfileForm.value.allowedIPAddresses;
  }

  onChangeBrandImage(event: any) {
    var imageUid = event.value;
    this.previewImage (imageUid);
  }

  onBlurBrandImage(event: any) {
    this.isImagePreviewShown = false;
  }

  previewImage (imageUid: any) {
    if (imageUid == '[none]') {
      this.isImagePreviewShown = false;
      return;
    }
    if (this.brandImages) {
      this.brandImages.forEach(element => {
        if (element.imageUID == imageUid) {
          let imageType = element.imageType;
          this.image = 'data:' + imageType + ';base64,' + element.imageContent;
          this.isImagePreviewShown = true;
        }
      });
    }
  }
  
  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-profile',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  onClickActivate(event: any) {
    this.confirmationService.confirm({
      message: "Please confirm your intention to make the brand ACTIVE for '"
      + this.currentBrand.brandCode + "' (" + this.currentBrand.brandName + ").",
      header: 'Warning',
      key: 'brand-profile',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
          this.isSpinnerHidden = false;
          this.brandProfileService.activateBrand(this.currentBrand.brandCode)
             .subscribe({
              next: (response) => {
                this.getBrandProfile(this.currentBrand.brandCode, this.currentBrand.baseUrl);
                this.isSpinnerHidden = true;
                this.alertTheUser("Successfully activated brand for '"
                  + this.currentBrand.brandCode + "' (" + this.currentBrand.brandName + ").");
                },
              error: (error) => {
                this.alertTheUser(error.message);
                this.isSpinnerHidden = true;
                  },
              complete: () => {
              }
            });
      }
    });
  }

  onClickDeactivate(event: any) {
    this.confirmationService.confirm({
      message: "Please confirm your intention to make the brand INACTIVE for '"
      + this.currentBrand.brandCode + "' (" + this.currentBrand.brandName + ").",
      header: 'Warning',
      key: 'brand-profile',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
          this.isSpinnerHidden = false;
          this.brandProfileService.deactivateBrand(this.currentBrand.brandCode)
             .subscribe({
              next: (response) => {
                // Disable all brand specific scheduled events
                this.disableScheduledEvents(this.currentBrand.brandCode, this.currentBrand.baseUrl);
                // Re-retrieve brand properties
                this.getBrandProfile(this.currentBrand.brandCode, this.currentBrand.baseUrl);
                this.isSpinnerHidden = true;
                this.alertTheUser("Successfully inactivated brand for '"
                  + this.currentBrand.brandCode + "' (" + this.currentBrand.brandName + ").");
                },
              error: (error) => {
                this.alertTheUser(error.message);
                this.isSpinnerHidden = true;
                  },
              complete: () => {
              }
            });
      }
    });
  }

  disableScheduledEvents(brandCode: string, baseUrl: string) {
    this.brandProfileService.disableScheduledEvents(brandCode, baseUrl)
    .subscribe({
     next: (response) => {
       },
     error: (error) => {
       this.alertTheUser(error.message);
       this.isSpinnerHidden = true;
         },
     complete: () => {
     }
   });

  }

  getIsBrandActive(isBrandActive: boolean) {
    if (isBrandActive)
      return 'success';
    else
      return 'danger';
  }  

}
