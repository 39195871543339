import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/safeguard/auth.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { BrandService } from '../../services/config/brand.service';
import { SharedService } from "../../services/shared.service";
import { IdleMonitorService } from '../../services/safeguard/idle-monitor.service';
import { IAuthToken } from '../../interfaces/auth-token';
import { IUserAuth } from '../../interfaces/user-auth';
import jwt_decode from "jwt-decode";
import { throwToolbarMixedModesError } from '@angular/material/toolbar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login:  any = {userName: '', password: '', newPassword: '', confirmPassword: ''};
  returnUrl: string = '';

  loading = false;
  submitted = false;
  isPasswordChangeRequired = false;
  // isPasswordShown = false;
  error: string = '';   
  authError: string = 'You are not authorized to use the Portal application';
  authFailed: string = 'User authentication failed';

  currentBrandSubject: Subject<any> = new Subject();
  
  constructor(
    // private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private sharedService: SharedService,
    private brandService: BrandService,
    private userContextService: UserContextService,
    private idleMonitorService: IdleMonitorService) { 
      // if (this.authenticationService.currentUserValue) {
      //   let portalRole = 'rl_portal';
      //   let user = this.authenticationService.currentUserValue;
      //   if (user.userName && user.userRoleList.includes(portalRole)) {
      //     this.router.navigate(['/']);
      //   }
      // }
    }

    ngOnInit() {
      this.login =  {userName: '', password: '', newPassword: '', confirmPassword: ''};
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }
     
    onSubmitClick() {
      if (this.isPasswordChangeRequired) {
        this.resetPassword();
      } else {
        this.submitLogin();
      }
    }

    submitLogin() {
      this.submitted = true;
      this.loading = true;
      this.error = '';
      this.isPasswordChangeRequired = false;
      this.authService.getAuthToken(this.login.userName, this.login.password)
      .subscribe({
        next: (authTokenResponse) => {
            // If we are not able to obtain an auth token, then no need to proceed with other steps
            if (typeof authTokenResponse === 'object' 
                && (authTokenResponse.hasOwnProperty('error') || !authTokenResponse.hasOwnProperty('accessToken'))) {
                  this.error = this.authFailed;
              this.loading = false;
              return;                      
            }
            // Save off auth and refresh tokens
            this.tokenStorageService.setAuthToken(authTokenResponse.accessToken || '');
            this.tokenStorageService.setRefreshToken(authTokenResponse.refreshToken || '');
            // We have a good token - retrieve a user profile, including a list of user's roles and attributes
            this.userContextService.findUserProfileByUserName(this.login.userName)
            .subscribe({
              next: (userProfile) => {
                var authToken : IAuthToken = jwt_decode(authTokenResponse.accessToken || '');
                console.log('authToken',authToken);
                var user : IUserAuth = {
                  userName: authToken.sub,
                  userRoleList: userProfile.userRoles,
                  userPrivilegeList: userProfile.userPrivileges,
                  userBrandList: authToken.brands,
                  userAttributeList: userProfile.userAttributes,
                  passwordChangeRequired: authToken.hasOwnProperty('pcr') ? authToken.pcr || false: false
                };
                // So, we now have all the user's details - make the decision where to navigate next
                // Make sure that the list of roles is not empty
                if (!(Array.isArray(user.userRoleList) && user.userRoleList.length)) {
                  this.error = this.authError;
                  this.loading = false;
                  return;                      
                }
                // Make sure that the list of privileges is not empty
                if (!(Array.isArray(user.userPrivilegeList) && user.userPrivilegeList.length)) {
                  this.error = this.authError;
                  this.loading = false;
                  return;                      
                }
                // Make sure that the user is granted at least one brand
                if (!(authToken.hasOwnProperty('allBrands') ? authToken.allBrands || false: false)
                  && (!(Array.isArray(authToken.brands) && authToken.brands.length))) {
                    this.error = this.authError;
                    this.loading = false;
                    return;                      
                }
                // Is the user required to change the password?
                if (user.passwordChangeRequired) {
                  this.login.password = null;
                  this.error = 'Password change required';
                  this.loading = false;
                  this.isPasswordChangeRequired = true;
                  return;                      
                }

                this.tokenStorageService.setCurrentUser(user);
                this.tokenStorageService.sendLoginEvent();

                // clean up current brand and template
                // this.sharedService.setCurrentPassTemplate(this.sharedService.getDummyPassTemplate());
                // this.sharedService.setCurrentOffer(this.sharedService.getDummyOffer());
                // this.sharedService.setCurrentBrandCode('');
                // this.sharedService.setCurrentBrand(this.sharedService.getDummyBrand());

                this.setCurrentBrand();

                this.sharedService.clearUserSession();

                // Successful authentication and authorization
                // console.log('login','successful');
                // if (this.userContextService.isBillerRep() && !this.userContextService.isAciStaff()) {
                //   console.log('login','Biller Home Page');
                //   this.router.navigate(["/homeBiller"]);
                // }
                // if (this.userContextService.isAciStaff() || this.userContextService.hasReadOnlyAccess()) {
                //   console.log('login','Home Page:' + this.returnUrl);
                //   this.router.navigate([this.returnUrl]);
                // }


                // console.log('this.returnUrl', this.returnUrl);
                this.router.navigate([this.returnUrl]);
                this.idleMonitorService.startIdleMonitoring();
               },
              error: (error) => {
                this.error = this.authFailed;
                this.loading = false;         
              },
              complete: () => {
              }
            });
        },
        error: (error) => {
          this.error = this.authFailed;
          this.loading = false; 
        },
        complete: () => {
        }
      });
    }

    resetPassword() {
      this.error = '';
      if (this.login.newPassword != this.login.confirmPassword) {
        this.error = "New and confirm passwords do not match";
        return;
      }
      if (this.login.newPassword === this.login.password) {
        this.error = "New and old passwords must be different";
        return;
      }
      this.userContextService.changeUserPassword(this.login.userName, this.login.password, this.login.newPassword)
      .subscribe({
        next: (response) => {
          this.error = 'Successfully changed the password';
          this.loading = false;
          this.isPasswordChangeRequired = false;
          this.login.password = '';
          this.login.newPassword = '';
          this.login.confirmPassword = '';
          return;                      
        },
        error: (error) => {
          this.login.password = '';
          this.login.newPassword = '';
          this.login.confirmPassword = '';
          this.error = 'Password must contain at least one digit, lowercase, uppercase and special characters with the length of 8 to 20 characters.';
          this.loading = false;
          this.isPasswordChangeRequired = true;
      },
        complete: () => {
        }
      });
    }

      // If the user has just one brand granted - make it as the default brand
      private setCurrentBrand() {
      let grantedBrands = this.userContextService.getUserBrands();
      if (grantedBrands) {
        if (grantedBrands.length == 1) {
          let brandCode: string = grantedBrands[0];
          this.brandService.getBrandWithRegion(brandCode)
          .subscribe (currentBrand => {
            if (currentBrand) {
              this.sharedService.setCurrentBrand(currentBrand);
              // this.currentBrandSubject.next(currentBrand);
            }
          });   
        }
      }  
    }


    // onCancelClick() {
    // }

    // onPasswordShowChange() {
    //   this.isPasswordShown = !this.isPasswordShown;
    // }

}
