<p-dialog  header="Filter Builder" [(visible)]="displayFilterBuilderDialog" [focusOnShow]="false" [responsive]="true"
    showEffect="fade" [modal]="true" [style]="{width: '65%'}" position="top"  [showHeader]="true" [closable]="false">


    @if(!isFilterConfigDialogVisible) {

      <p-card header="Filters" subheader="" styleClass="ui-card-light">

          <div class="grid">
              <div class="col-12">
      
                  <p-table #dt [columns]="filterConfigsCols" [value]="filterConfigs" selectionMode="single" 
                      [(selection)]="selectedFilterConfig"  
                      sortMode="multiple" (onRowSelect)="onRowFilterConfigSelect($event)" [paginator]="true" [rows]="15" >
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '15%'}"></th>
                      </tr>
                        <tr>
                          <th *ngFor="let col of columns" [ngSwitch]="col.field"  [ngStyle]="{'width': col.width}">
                            <input *ngSwitchCase="'filterName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                            <input *ngSwitchCase="'filterDescription'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                          </th>
                      </tr>  
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns">
                                {{rowData[col.field]}}
                            </td>

                            <td style="text-align:center">
                                <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}" 
                                    data-toggle="tooltip" data-placement="top" title="Modify Filter" 
                                    class="btn ui-button-info p-mr-2" (click)="onModifyFilter(rowData)">
                                </button> 
                                <button pButton type="button" icon="pi pi-clone" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                    data-toggle="tooltip" data-placement="top" title="Clone Filter" 
                                    class="btn ui-button-info" (click)="onCloneFilter(rowData)" >
                                </button>
                                <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                    data-toggle="tooltip" data-placement="top" title="Delete Filter" 
                                    class="btn ui-button-danger" (click)="onDeleteFilter(rowData)" style="margin-right:10px">
                                </button>                                
                            </td>

                        </tr>
                    </ng-template>
                  </p-table> 
            
              </div>
          </div>
  
  
          <p-footer>

              <div class="ui-g btn-group" role="group">
  
                  <p-button type="button" label="New Filter" (click)="onNewFilter()"
                      styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                  </p-button>                                    
  
                  @if (isSelectFilterButtonShown) {
                    <p-button type="button" label="Select Filter" (click)="onSelectFilter()"
                        styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                    </p-button>                                    
                    }
  
                  <p-button type="button" label="Close" (click)="onCancelFilterBuilder()"
                      styleClass="p-button-primary p-button-raised p-button-rounded margin-left">
                  </p-button>                                    
  
              </div>
  
          </p-footer>
  
      </p-card>
  
  }


    @if(isFilterConfigDialogVisible) {

        <p-card header="Filter Builder" subheader="" styleClass="ui-card-light">

        <form [formGroup]="filterForm" (ngSubmit)="onSubmit(filterForm.value)">

            <div>

            <div class="field grid">
                <label for="filterName" class="col-fixed label" style="width:10em">Filter Name:</label>
                <div class="col">
                    <input pInputText id="filterName" type="text" formControlName="filterName" [style]="{'width': '100%'}" 
                        [size]="255" placeholder="Required" pTooltip="Discriptive filter name" tooltipPosition="top"/> 
                    <p-message severity="error" text="filter name is required" *ngIf="!filterForm.controls['filterName'].valid && filterForm.controls['filterName'].dirty"></p-message>                 
                </div>
            </div>

            <div class="field grid">
                <label for="filterDescription" class="col-fixed label" style="width:10em">Filter Description:</label>
                <div class="col">
                    <input pInputText id="filterDescription" type="text" formControlName="filterDescription" [style]="{'width': '100%'}" 
                        [size]="2000" placeholder="Required" pTooltip="Discriptive filter description" tooltipPosition="top"/> 
                    <p-message severity="error" text="filter description is required" *ngIf="!filterForm.controls['filterDescription'].valid && filterForm.controls['filterDescription'].dirty"></p-message>                 
                </div>
            </div>

            <div class="grid">

                <div class="col-6">
                    <div class="field grid">
                        <label for="modifiedByUser" class="col-fixed label" style="width:10em">Modified By:</label>
                        <div class="col">
                            <input pInputText id="modifiedByUser" type="text" formControlName="modifiedByUser" [style]="{'width': '75%'}" readonly="true"/> 
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="field grid">
                        <label for="lastUpdateDate" class="col-fixed label" style="width:10em">Modified Date:</label>
                        <div class="col">
                            <input pInputText id="lastUpdateDate" type="text" formControlName="lastUpdateDate" [style]="{'width': '75%'}" readonly="true"/> 
                        </div>
                    </div>
                </div>

            </div>

            <query-builder [formControl]='filterCtrl' [config]='config' [allowCollapse]="true"></query-builder>

            @if (isFilterSyntaxVisible) {
                <textarea class="output" > {{filter | json}}</textarea>   
            }


            </div>

            <div class="col-12">
            <div  class="col-2" class="row container-fluid"  id="divshow">
                <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!filterForm.valid" ></p-button>
                <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelNewFilterBuilder()"></p-button>
            </div> 
            <div class="col-10">
                <mat-label>{{resultMsg}}</mat-label>
            </div>
            </div>

        </form>

            <!-- <p-footer> -->
                <div style="text-align: right">
                    <p-inputSwitch [(ngModel)]="isFilterSyntaxVisible"></p-inputSwitch>
                </div>
            <!-- </p-footer> -->
        
        </p-card>

    }

</p-dialog>



<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="filter-builder"></p-confirmDialog>