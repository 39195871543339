import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { IEnrollmentStats } from '../../interfaces/enrollment-stats';
import { BrandStatsService } from "../../services/stats/brand-stats.service";
import { ConfirmationService } from "primeng/api";
import { SharedService } from "../../services/shared.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { BrandService } from '../../services/config/brand.service';
import { IBrand } from '../../interfaces/brand';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-enrollment-stats',
  templateUrl: './enrollment-stats.component.html',
  styleUrls: ['./enrollment-stats.component.css']
})
export class EnrollmentStatsComponent implements OnInit {

  @ViewChild(Table) dt!: Table;

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  titleMessage = "Enrollment Statistics";
  currentBrand!: IBrand;
  isBrandSelectionButtonShown: boolean = false;
  openBrandSelectionDialog!: boolean;
  isDetailsShown = false;
  defaultDate: any;
  brandName!: string;
  brandList = [];

  envRegions = [];
  brands = [];
  baseUrl!: string;
  selectedBrand!: string;
  selectedBrandName!: string;
  selectedDateFrom!: string;
  selectedDateTo!: string;

  enrollmentStatsCols!: any[];
  enrollmentStats: IEnrollmentStats[] = [];
  enrollmentStatsChart: any;
  optionsEnrollments: any;

  isSpinnerHidden: boolean = true;
  isSamsungPlatformEnabled: boolean = false;
 
  constructor( 
    private tokenStorageService: TokenStorageService,    
    private brandService: BrandService,    
    private brandStatsService: BrandStatsService, 
    private confirmationService: ConfirmationService,
    private sharedService: SharedService) {}

  ngOnInit() {
      // Define the columns for EnrollmentStats grid
      this.enrollmentStatsCols = [
        { field: 'source', header: 'Source', width:'33%', display: 'table-cell' },
        { field: 'platform', header: 'Platform', width:'33%', display: 'table-cell' },
        { field: 'cnt', header: 'Count', width:'33%', display: 'table-cell' }
      ]; 

      this.optionsEnrollments = this.sharedService.options('Enrollment Counts by Source and Platform');
      this.selectedDateFrom = this.sharedService.getOneWeekAgoDate();
      this.selectedDateTo = this.sharedService.getCurrentDate();

    // Figure out whether the user has just one granted brand - in this case we can eliminate brand selection altogether
    let assignedBrand = this.tokenStorageService.getCurrentUserAssignedBrand();
    if (assignedBrand) {
      this.setSingleBrand(assignedBrand);
    } else {    
      this.isBrandSelectionButtonShown = true;  
      this.currentBrand = this.sharedService.getCurrentBrand();
      if (this.currentBrand != null) {
        this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
        this.selectedBrand = this.currentBrand.brandCode || '';
        this.baseUrl = this.currentBrand.baseUrl || '';
        this.isSamsungPlatformEnabled = this.currentBrand.isSamsungPlatformEnabled || false;
        this.openBrandSelectionDialog = false;
      } else {
        this.openBrandSelectionDialog = true;
      } 
    }
  }

  // Set single brand as the default when the user has just one brand granted
  setSingleBrand(brandCode: string) {
    this.isBrandSelectionButtonShown = false;
    this.isSpinnerHidden = false;
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe({
      next: (brand) => {
        this.currentBrand = brand;
        this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
        this.baseUrl = brand.baseUrl || '';
        this.selectedBrand = brand.brandCode || '';
        this.isSamsungPlatformEnabled = this.currentBrand.isSamsungPlatformEnabled || false;
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  onClearClick(event: any) {
    // this.baseUrl = null;
    // this.selectedBrand = null;
    this.selectedDateFrom = '';
    this.selectedDateTo = '';
  }

  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.sharedService.setCurrentBrand(item);
      this.selectedBrand = item.brandCode || '';
      this.selectedBrandName = " ( " + item.brandName + " )";
      this.baseUrl = item.baseUrl || '';
      this.isSamsungPlatformEnabled = this.currentBrand.isSamsungPlatformEnabled || false;
      this.enrollmentStats = [];
      this.enrollmentStatsChart = [];
      // console.log('chooseBrand', this.isSamsungPlatformEnabled);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
    }
  }

  // Process the Search request
  onSearchClick(event: any) {
    if (!(this.baseUrl && this.selectedBrand && this.selectedDateFrom && this.selectedDateTo)) {
      this.confirmationService.confirm({
        message: 'Make sure to select a brand and the date interval in the selection criteria to retireve database records.',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });          
      return;
    }
    this.isSpinnerHidden = false;
    this.brandStatsService.getEnrollmentStats(this.baseUrl, this.selectedBrand, this.selectedDateFrom, this.selectedDateTo)
    .subscribe({
      next: (result) => {
        this.isSpinnerHidden = true;
        if (result.length == 0) {
          this.enrollmentStatsChart = {
            labels: [],
            datasets: []
          }
          this.confirmationService.confirm({
            message: 'No records found for the specified selection criteria.',
            header: 'Warning',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Ok",
            rejectVisible: false,
            acceptButtonStyleClass: "p-button-info  p-button-rounded",
            accept: () => {
                  return;
                }
          });          
        }
        else {
          // console.log('onSearchClick', this.isSamsungPlatformEnabled);
          // Populate the grid
          this.enrollmentStats = result;
          // Populate the chart
          var labels = [];
          var appleWallet = [];
          var googlePay = [];
          var samsungWallet = [];
          for(let i = 0; i < result.length; i++){
            var index = labels.findIndex(element => element == result[i].source);
            if (index < 0) {
              labels.push(result[i].source);
            }
          }
          for(let i = 0; i < result.length; i++){
            var index = labels.findIndex(element => element == result[i].source);
            if (index >= 0) {
              if (result[i].platform == 'Apple Wallet') {
                appleWallet[index] = result[i].cnt;
              } else if (result[i].platform == 'Google Pay') {
                googlePay[index] = result[i].cnt;
              } else if (result[i].platform == 'Samsung Wallet') {
                samsungWallet[index] = result[i].cnt;
              }
            }
          }

          this.enrollmentStatsChart = {
            labels: labels,
            datasets: [
                {
                    label: 'Apple Wallet',
                    backgroundColor: 'blue',
                    borderColor: 'darkblue',
                    data: appleWallet
                },
                {
                  label: 'Google Pay',
                  backgroundColor: 'green',
                  borderColor: 'darkgreen',
                  data: googlePay
                }
            ]
          } 
          if (this.isSamsungPlatformEnabled) {
            this.enrollmentStatsChart.datasets.push(
              {
                label: 'Samsung Wallet',
                backgroundColor: 'red',
                borderColor: 'darkred',
                data: samsungWallet
              }                  
            );
          }
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

}

