export const environment = {
    production: false,
    env: {
        name:"UAT",
        banner: "Test Environment"
    },
    apiServer:  {
        webSocketlUrl: "wss://portalserver-uat.acimobills.com/portal",
        portalUrl: "https://portalserver-uat.acimobills.com/portal/api",
        authServerUrl: "https://portalserver-uat.acimobills.com/auth/api",
        rtpnUrl: "https://uat.walletron.click/rtpn/api"         
    },
    appSetting: {
        baseAuth: "Basic YWRtaW46d2FsbGV0cm9uZ2Y=",
        portalAuth: "Basic dXNyX21vbml0b3I6TUdsM21JM1dJaVpXVTdFWQ==",
        communicationAuth: "Basic dXNyX2NvbW1fbW5ncjpaVmFYbkw3dGNWclNWSkVR"
    },
    brandMigration: {
        sourceRegions:  ["qa", "demo", "client2prod", "mbprod", "mobills2prod", "wuprod"],
        targetRegions: ["uat"]
    }
};
