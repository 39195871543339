import { Component, OnInit, ViewChild } from '@angular/core';
import { INotificationStats } from '../../interfaces/notification-stats';
import { BrandStatsService } from "../../services/stats/brand-stats.service";
import { ConfirmationService } from "primeng/api";
import { SharedService } from "../../services/shared.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { BrandService } from '../../services/config/brand.service';
import { IBrand } from '../../interfaces/brand';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-notification-stats',
  templateUrl: './notification-stats.component.html',
  styleUrls: ['./notification-stats.component.css']
})
export class NotificationStatsComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  titleMessage = "Notification Statistics";
  currentBrand!: IBrand;
  openBrandSelectionDialog!: boolean;
  isBrandSelectionButtonShown: boolean = true;
  isDetailsShown = false;
  defaultDate: any;
  brandName!: string;
  brandList: any[] = [];

  envRegions: any[] = [];
  brands: any[] = [];
  baseUrl!: string;
  selectedBrand!: string;
  selectedBrandName!: string;
  selectedDateFrom!: string;
  selectedDateTo!: string;

  notificationStatsCols: any[] = [];
  notificationStats: INotificationStats[] = [];
  notificationStatsChart: any;
  optionsNotifications: any;

  isSpinnerHidden: boolean = true;
 
  constructor(  
    private tokenStorageService: TokenStorageService,    
    private brandService: BrandService,  
    private brandStatsService: BrandStatsService, 
    private confirmationService: ConfirmationService,
    private sharedService: SharedService) { }

  ngOnInit() {
      // Define the columns for EnrollmentStats grid
      this.notificationStatsCols = [
        { field: 'passName', header: 'Pass Name', width:'33%', display: 'table-cell' },
        { field: 'templateName', header: 'Template Name', width:'33%', display: 'table-cell' },
        { field: 'count', header: 'Count', width:'33%', display: 'table-cell' }
      ]; 

      this.optionsNotifications = this.sharedService.options('Notification Counts by Template');
      this.selectedDateFrom = this.sharedService.getOneWeekAgoDate();
      this.selectedDateTo = this.sharedService.getCurrentDate();

    // Figure out whether the user has just one granted brand - in this case we can eliminate brand selection altogether
    let assignedBrand = this.tokenStorageService.getCurrentUserAssignedBrand();
    if (assignedBrand) {
      this.setSingleBrand(assignedBrand);
    } else {      
      this.isBrandSelectionButtonShown = true;
      this.currentBrand = this.sharedService.getCurrentBrand();
      if (this.currentBrand != null) {
        this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
        this.selectedBrand = this.currentBrand.brandCode || '';
        this.baseUrl = this.currentBrand.baseUrl || '';
        this.openBrandSelectionDialog = false;
      } else {
        this.openBrandSelectionDialog = true;
      } 
    }
  }

  // Set single brand as the default when the user has just one brand granted
  setSingleBrand(brandCode: string) {
    this.isBrandSelectionButtonShown = false;
    this.isSpinnerHidden = false;
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe (brand => {
      this.currentBrand = brand;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.baseUrl = brand.baseUrl || '';
      this.selectedBrand = brand.brandCode || '';
      this.isSpinnerHidden = true;
    });
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  onClearClick(event: any) {
    // this.baseUrl = null;
    // this.selectedBrand = null;
    this.selectedDateFrom = '';
    this.selectedDateTo = '';
  }

  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.sharedService.setCurrentBrand(item);
      this.selectedBrand = item.brandCode || '';
      this.selectedBrandName = " ( " + item.brandName + " )";
      this.baseUrl = item.baseUrl || '';
      this.notificationStats = [];
      this.notificationStatsChart = [];
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
    }
  }

  // Process the Search request
  onSearchClick(event: any) {
    if (!(this.baseUrl && this.selectedBrand && this.selectedDateFrom && this.selectedDateTo)) {
      this.confirmationService.confirm({
        message: 'Make sure to select a brand and the date interval in the selection criteria to retireve database records.',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });          
      return;
    }
    this.isSpinnerHidden = false;
    this.brandStatsService.getNotificationStats(this.baseUrl, this.selectedBrand, this.selectedDateFrom, this.selectedDateTo)
      .subscribe(result => {
        this.isSpinnerHidden = true;
        if (result.length == 0) {
          this.notificationStatsChart = {
            labels: [],
            datasets: []
          }
          this.confirmationService.confirm({
            message: 'No records found for the specified selection criteria.',
            header: 'Warning',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Ok",
            rejectVisible: false,
            acceptButtonStyleClass: "p-button-info  p-button-rounded",
            accept: () => {
                  return;
                }
          });          
        }
        else {
          // Populate the grid
          this.notificationStats = result;
          // Populate the chart
          var labels = [];
          var notificationCounts = [];
          for(let i = 0; i < result.length; i++){
            var index = labels.findIndex(element => element == result[i].templateName);
            if (index < 0) {
              labels.push(result[i].templateName);
              notificationCounts.push(0);
            }
          }
          for(let i = 0; i < result.length; i++){
            var index = labels.findIndex(element => element == result[i].templateName);
            if (index >= 0) {
              notificationCounts[index] += result[i].count;
            } 
          }

          this.notificationStatsChart = {
            labels: labels,
            datasets: [
                {
                    label: 'Notification Counts',
                    backgroundColor: 'blue',
                    borderColor: 'darkblue',
                    data: notificationCounts
                }
            ]
          } 
        }

      });
  }

}
