<div class="grid">

    <div class="col-12">
        <p-card header="RTPN Transactions Log" subheader="" styleClass="ui-card-light" >

            <!--  C R I T E R I A  -->
            <div class="grid">

                <div class="grid col-5">

                    <div class="col-4">
                        <label for="dateFrom" class="margin-right">Date From:</label>
                        <p-calendar id="dateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" 
                        [showIcon]="true" class="margin-left"></p-calendar>
                    </div>
    
                    <div class="col-4">
                        <label for="dateTo" class="margin-left">Date To:</label>
                        <p-calendar id="dateTo" [(ngModel)]="selectedDateTo" dateFormat="yy-mm-dd" 
                           [showIcon]="true" class="margin-left"></p-calendar>
                    </div>

                    <div class="col-4">
                        <label for="accountNumber">Account Number:</label>
                        <input pInputText id="accountNumber" class="form-control mb-2 mr-sm-2" type="text" [(ngModel)]="selectedAccountNumber">
                    </div>

                </div>

                <div class="grid col-3">

                    <div class="col-4" *ngIf="isBrandSelectionShown">
                        <label for="billerCode">Brand Code:</label>
                        <p-dropdown id ="billerCode" [options]="billerCodes" [(ngModel)]="selectedBrandCode" 
                            (onChange)="onChangeBiller($event)"
                            [editable]="false" class="margin-left" [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
    
                    <div class="col-4">
                        <label for="requestType">Request Type:</label>
                        <p-dropdown id ="requestType" [options]="requestTypes" [(ngModel)]="selectedRequestType"
                            class="margin-left" [style]="{'width':'100%'}" [editable]="false">
                        </p-dropdown>
                    </div>
    
                    <div class="col-4">
                        <label for="statusCode">Status Code:</label>
                        <p-dropdown id ="statusCode" [options]="statusCodes" [(ngModel)]="selectedStatusCode" 
                            class="margin-left" [style]="{'width':'100%'}" [editable]="false">
                        </p-dropdown>
                    </div>
    
                </div>

                <div class="grid col-4">

                    <div class="col-2"></div>

                    <div class="col-8">
                        <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
                        <!-- <p-button label="Next" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="isNextButtonShown($event)"></p-button> -->
                        <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)"></p-button>
                    </div>
    
                    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
                    <div class="loader"></div>  
                    </div>
    
                </div>

            </div>  

            <!--  G R I D  -->
            
            <div class="grid">

                <p-table #dtRtpnTxn [columns]="rtpnTransactionsCols" [value]="rtpnTransactions" selectionMode="single" 
                    [(selection)]="selectedRtpnTransaction" styleClass="p-datatable-striped p-datatable-sm"
                     [rowsPerPageOptions]="[10,15,25,50]" [showCurrentPageReport]="true" dataKey="rtpnRequestId"
                    sortMode="multiple" (onRowSelect)="onRtpnTransactionRowSelect($event)" [paginator]="true" [rows]="25">
                    <ng-template pTemplate="caption">
                    Log of RTPN Transactions
                    </ng-template>
                    <ng-template pTemplate="caption">
                        <div style="text-align: right">        
                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                            <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dtRtpnTxn.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th style="width: 2.25em"></th>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="p-column-title" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>

<!--     
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
    -->

                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
                        <tr [pSelectableRow]="rowData">
                        <td [ngStyle]="{'width': '3%'}">
                            <button type="button" pButton pRipple [pRowToggler]="rowData" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                        </td>
                        </tr>
                    </ng-template>

                    
                    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                        <tr>
                            <td [attr.colspan]="columns.length + 1">
                                <div class="ui-g ui-fluid" style="font-size:11px;padding:7px">
                                    <div class="ui-g-12 ui-md-9">
                                        <div class="grid">
                                        <div class="col-12">
                                            <b>Execution Code:</b> {{rowData.exitCode}}
                                        </div>
                                        <div class="col-12">
                                                <b>Execution Message:</b> {{rowData.errorMsg}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div style="text-align:left">
                            <div class="ui-helper-clearfix">
                                <p-button label="Prior" *ngIf="isPriorButtonShown" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onPriorClick($event)"></p-button> 
                                <p-button label="Next"  *ngIf="isNextButtonShown" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onNextClick($event)"></p-button> 
                                <p-button styleClass="p-button-info p-button-raised p-button-rounded margin-left float-right"
                                    label="Export" (click)="onFileCreationRequest($event)" >
                                </p-button> 
                            </div>
                        </div>
                    </ng-template>   

                </p-table> 

            </div>

            <p-footer>
                <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
            </p-footer>
        </p-card>
    </div>            

</div>    

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="rtpn-transactions"></p-confirmDialog>
