import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldBarCode extends OfferFieldBase {

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.setOfferFieldAttributes();
    } 

    // Field Label, Field Value, Bar Code Type, Show/hide Field Expression,

    public setOfferFieldAttributes() {

    this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldLabel',
            label: 'Field Label',
            value: this.data.fieldLabel,
            styleWidth: '25em',
            required: false,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'barCodeType',
            label: 'Bar Code Type',
            value: this.data.barCodeType,
            required: true,
            tooltip: 'Select the proper bar code type',
            options: [
            {label: '[none]',  value: ''},
            {label: 'QR Code',  value: 'PKBarcodeFormatQR'},
            {label: 'PDF 417',  value: 'PKBarcodeFormatPDF417'},
            {label: 'Aztec',  value: 'PKBarcodeFormatAztec'},
            {label: 'Code 128',  value: 'CODE_128'},
            {label: 'UPC-A',  value: 'UPC_A'},
            {label: 'EAN-13',  value: 'EAN_13'},
            {label: 'EAN-8',  value: 'EAN_8'}
            ],
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'changeMessage',
            label: 'Change Message',
            value: this.data.changeMessage,
            styleWidth: '20em',
            required: false,
            order: 7
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'positionSpec',
            label: 'Position Spec',
            value: this.data.positionSpec,
            styleWidth: '40em',
            required: false,
            tooltip: 'Position specification for Google and/or Samsung devices',
            order: 8
        }));
        
        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 9
        }));
        
        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }

}
