import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators,FormControl,FormGroup,FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
// import { switchMap } from 'rxjs/operators';
import { ConfirmationService } from "primeng/api";
import { RtpnSimulatorService } from "../../services/rtpn/rtpn-simulator.service";
import { UserContextService } from "../../services/safeguard/user-context.service";
import { SharedService } from "../../services/shared.service";
import { RtpnTransactionsService } from "../../services/rtpn/rtpn-transactions.service";
// import { IRtpnTransaction } from '../../interfaces/rtpn-transaction';

@Component({
  selector: 'app-rtpn-simulator',
  templateUrl: './rtpn-simulator.component.html',
  styleUrls: ['./rtpn-simulator.component.css']
})
export class RtpnSimulatorComponent implements OnInit{

  @ViewChild('dtTxns') dtTxns:any;  
  isDialogDisplayed: boolean = false;
  isSpinnerHidden: boolean = true;
  isBarcodelookupDisabled: boolean = true;
  brandCodes: any = [];
  isDisabled = false;
  isSubmitShown = true;
  resultMsg = "";

  txnDisplayDialog!: boolean;
  txnCols!: any[];
  txns!: any[];
  selectedDateFrom!: string;
  selectedDateTo!: string;

  rtpnForm!: FormGroup;
  barcodeForm!: FormGroup;

  barcodeImage: any;
  barcodeImageUrl!: string;
  isBarcodeImageUrlShown: boolean = false;

    constructor(
      private fb: FormBuilder, 
      private http: HttpClient, 
      protected sanitizer: DomSanitizer,
      private sharedService: SharedService,
      private rtpnSimulatorService: RtpnSimulatorService,
      private rtpnTransactionsService: RtpnTransactionsService,
      private userContextService: UserContextService,
      private confirmationService: ConfirmationService) {
    }
  
    ngOnInit() {
      this.brandCodes = [];

      // Figure out the list of granted brands to the current user
      let hasAllBrands = this.userContextService.hasAllBrands();
      this.getRtpnBillers(hasAllBrands);
      // Prepare form definitions
      this.rtpnForm = this.fb.group({
        'barcodeValue': new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(10)]),
        'transactionAmount': new FormControl('', [Validators.required, Validators.maxLength(10)])
      });      
      this.barcodeForm = this.fb.group({
        'brandCode': new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]),
        'accountId': new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(2)]),
      }); 
      // Prepopulate the dates
      this.selectedDateFrom = this.sharedService.getOneWeekAgoDate();
      this.selectedDateTo = this.sharedService.getCurrentDate();
      // Define the columns for Transaction Log grid
      this.txnCols = [
        { field: 'billerId', header: 'Biller Id', width:'7%', display: 'table-cell' },
        { field: 'brandCode', header: 'Brand Code', width:'6%', display: 'table-cell' },
        { field: 'billerName', header: 'Biller Name', width:'15%', display: 'table-cell' },
        { field: 'accountNumber', header: 'Account No', width:'9%', display: 'table-cell' },
        { field: 'transactionId', header: 'Transaction Id', width:'12%', display: 'table-cell' },
        { field: 'transactionDate', header: 'Transaction Date', width:'12%', display: 'table-cell' },
        { field: 'status', header: 'Status', width:'7%', display: 'table-cell' },
        { field: 'amount', header: 'Amount', width:'8%', display: 'table-cell' }
      ];     
      // Get a list of recent transactions
      this.getTxnLog(this.selectedDateFrom, this.selectedDateTo);
    }
  
    // getRtpnBillers(hasAllBrands: boolean) {
    //   this.isSpinnerHidden = false;
    //   this.rtpnSimulatorService.getRtpnBillers()
    //   .subscribe({
    //     next: (response) => {
    //       if (response) {
    //         response.forEach(biller => {
    //           if (hasAllBrands || this.userContextService.hasBrandGranted(biller.brandCode)) {
    //             this.brandCodes.push({label: biller.brandCode + ' (' + biller.billerName + ')', value: biller.brandCode});
    //           }
    //         })
    //       }
    //       if (this.brandCodes.length > 0) {
    //         this.isBarcodelookupDisabled = false;
    //       }
    //       this.isSpinnerHidden = true;
    //     },
    //     error: (error) => {
    //       this.alertTheUser("Unable to retrieve RTPN billers.");
    //       this.isSpinnerHidden = true;
    //     },
    //     complete: () => {
    //     }
    //   });
    // }

    getRtpnBillers(hasAllBrands: boolean) {
      this.isSpinnerHidden = false;
      this.rtpnTransactionsService.getRtpnBrands()
      .subscribe({
        next: (response) => {
          if (response) {
            response.forEach(biller => {
              if (hasAllBrands || this.userContextService.hasBrandGranted(biller.brandCode)) {
                this.brandCodes.push({label: biller.brandCode + ' (' + biller.brandName + ')', value: biller.brandCode});
              }
            })
          }
          if (this.brandCodes.length > 0) {
            this.isBarcodelookupDisabled = false;
          }
          this.isSpinnerHidden = true;
        },
        error: (error) => {
          this.alertTheUser("Unable to retrieve RTPN billers.");
          this.isSpinnerHidden = true;
        },
        complete: () => {
        }
      });
    }

    onBarcodeLookup() {
      this.barcodeForm.setValue({
        brandCode: null,
        accountId: null
      });
      this.isDialogDisplayed = true;
    }

    onBarcodeSubmit(formValue: any) {
      this.isSpinnerHidden = false;
      this.isBarcodeImageUrlShown = false;
      let brandCode = this.barcodeForm.value.brandCode;
      let accountId = this.barcodeForm.value.accountId;
      this.rtpnSimulatorService.getBarcode(brandCode, accountId)
      .subscribe({
        next: (response) => {
          console.log('barcode response', response);
          if (response) {
            // Prepopulate the form to submit a payment
            this.rtpnForm.setValue({
              barcodeValue: response.barcodeValue,
              transactionAmount: 0
            });
            if (response.barcodeImageUrl) {
              this.barcodeImageUrl = response.barcodeImageUrl;
              this.isBarcodeImageUrlShown = true;
            }
            // // Display the barcode
            // this.rtpnSimulatorService.getBarcodeImage(response.barcodeImageUrl)
            // .subscribe({
            //   next: (resp) => {
            //     if (resp) {
            //       let base64ImageUrl = this.sharedService.convertBlobToBase64(resp);
            //       this.barcodeImage =
            //       this.sanitizer.bypassSecurityTrustResourceUrl(base64ImageUrl);
            //     }
            //   },
            //   error: (error) => {
            //   }
            // })
          }
          this.isDialogDisplayed = false;
          this.isSpinnerHidden = true;
        },
        error: (error) => {
          this.alertTheUser("The entered account was not found for the chosen brand.");
          this.isSpinnerHidden = true;
        },
        complete: () => {
        }
      });
    }

    onRtpnSubmit(formValue: any) {
      let barcodeValue = this.rtpnForm.value.barcodeValue;
      let amount = this.rtpnForm.value.transactionAmount;
      if (amount == 0) {
        this.alertTheUser("Transaction amount must be non-zero.");
        return;
      }
      this.isSpinnerHidden = false;
      this.rtpnSimulatorService.processRtpnTransaction(barcodeValue, amount)
      .subscribe({
        next: (response) => {
          this.getTxnLog(this.selectedDateFrom, this.selectedDateTo);
          this.isSpinnerHidden = true;
          this.alertTheUser("Your transaction has been successfully posted.");
        },
        error: (error) => {
          this.isSpinnerHidden = true;
          this.alertTheUser('Error Code: ' + error.status + '.  Message: ' + error.error.message);
        },
        complete: () => {
        }
      });
    }

    cancel() {
      this.barcodeForm.setValue({
        brandCode: null,
        accountId: null
      });
      this.isDialogDisplayed = false;
    }

   // Select RTPN transactions log based on the user defined selection criteria
   getTxnLog(dateFrom: string, dateTo: string): void {
    this.isSpinnerHidden = false;
    let pageSize = 50;
    console.log('getTxnLog');
    // this.dtTxns.reset();
    this.rtpnTransactionsService.getLatestPayments(dateFrom, dateTo, pageSize)
    // this.rtpnTransactionsService.getRtpnRequests(billerCode, requestType, statusCode, accountNumber,
    //   dateFrom, dateTo, nextPageToken, direction, pageSize)
      .subscribe(response => {
        if (response) {
          this.txns = response.rtpnRequests;
          // Format the amounts
          for(let i = 0; i < this.txns.length; i++){
            if (this.txns[i].amount) {
              this.txns[i].amount = this.txns[i].amount
                .toLocaleString("en-US", {style: "currency",currency: "USD"});
            }
          }
       }
      // this.txns = this.formatAmount(response);
      // this.txns = this.formatDate(response);
        this.isSpinnerHidden = true;
      });
    }    

    alertTheUser(message: string) {
      this.confirmationService.confirm({
        message: message,
        header: 'CSR Account',
        key: 'rtpn-simulator',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
           return;
         }
      });
    }
    
}
