import { Validators, ValidatorFn, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { IOfferField } from '../interfaces/offer-field';
import { OfferFieldAttribute } from './offer-field-attribute';

// import { Injector } from "@angular/core";
// import { BrandProfileService } from '../services/construct/brand-profile.service';

export class OfferFieldBase{

    private offerFieldTypes: any[];
    protected data: IOfferField;
    public formGroup!: FormGroup;
    public offerFieldAttributes: OfferFieldAttribute<string>[] = [];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[]
    ) { 
        this.data = offerFieldData;
        this.offerFieldTypes = offerFieldTypes;
        this.setOfferFieldKeyAttributes();
    }    

    private setOfferFieldKeyAttributes() {

        this.offerFieldAttributes.length = 0;

        // Offer Field Id, Field Type, Field Key

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'offerFieldId',
            label: 'Offer Field Id',
            value: this.data.offerFieldId ? this.data.offerFieldId.toString() : '',
            required: false,
            readonly: true,
            styleWidth: '15em',
            order: 1
        }));
        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldType',
            label: 'Field Type',
            value: this.data.fieldType,
            required: true,
            styleWidth: '25em',
            order: 2,
            options: this.offerFieldTypes
        }));
        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldKey',
            label: 'Field Key',
            value: this.data.fieldKey,
            required: true,
            validator: 'alpha-numeric',
            styleWidth: '15em',
            order: 3
        }));
    }

    public getOfferFieldAttributes(): OfferFieldAttribute<string>[] {

        return this.offerFieldAttributes;

    }

    public getFormGroup(): FormGroup {
        return this.formGroup;
    }

    public setFormGroup() {
        var fieldGroup: any = {};
        this.offerFieldAttributes.forEach(element => {
            let validator: ValidatorFn[] = element.required ? [Validators.required] : [];
            switch (element.validator) {
                case "email":
                    validator.push(Validators.email);
                    break;
                case "numeric":
                    validator.push(Validators.pattern('^[0-9]*$'));
                    break;
                case "alpha-numeric":
                    validator.push(Validators.pattern('^[a-zA-Z0-9_]*$'));
                    break;
                // case "url":
                //     validator.push(Validators.pattern('[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?'));
                //     break;
                default:
                    break;
            }            
            fieldGroup[element.key] = validator.length > 0 ? new FormControl(element.value,  validator)
                : new FormControl(element.value);
            // fieldGroup[element.key] = element.required ? new FormControl(element.value || '', Validators.required)
            //     : new FormControl(element.value || '');
        });
        this.formGroup = new FormGroup(fieldGroup);
    }

    public getFormData(): IOfferField {
        if (this.formGroup.value.hasOwnProperty('offerFieldId')) {
            this.data.offerFieldId = this.formGroup.value.offerFieldId;
        }
        if (this.formGroup.value.hasOwnProperty('offerId')) {
            this.data.offerId = this.formGroup.value.offerId;
        }
        if (this.formGroup.value.hasOwnProperty('fieldType')) {
            this.data.fieldType = this.formGroup.value.fieldType;
        }
        if (this.formGroup.value.hasOwnProperty('fieldKey')) {
            this.data.fieldKey = this.formGroup.value.fieldKey;
        }
        // There is a need to use two different controls on the form for the same field in the database.
        // For example, "color picker" control or "inputtext" for an expression when choosing a color.  Only one of the controls is populated by the user.
        // If the "primary" contol does not have a value, and the second "beta" control has a value, then we use it for the model to be sent over via Web API.
        if (this.formGroup.value.hasOwnProperty('fieldValue')) {
            this.data.fieldValue = this.formGroup.value.fieldValue;
            if (this.formGroup.value.hasOwnProperty('fieldValueBeta')) {
                if (!this.formGroup.value.fieldValue && this.formGroup.value.fieldValueBeta) {
                    this.data.fieldValue = this.formGroup.value.fieldValueBeta;
                }
            }
        }
        // Suppress leading pound sign if it is not used for exxpression logic
        if (this.data.fieldType == "fgColorField" || this.data.fieldType == "bgColorField" || this.data.fieldType == "fgLblColorField") {
            if (this.data.fieldValue.substring(0,1) == "#" && this.data.fieldValue.substring(0,2) != "#{") {
                this.data.fieldValue = this.data.fieldValue.substring(1);
            }
        }
        if (this.formGroup.value.hasOwnProperty('variableName')) {
            this.data.variableName = this.formGroup.value.variableName;
        }
        if (this.formGroup.value.hasOwnProperty('fieldOrder')) {
            this.data.fieldOrder = this.formGroup.value.fieldOrder;
        }
        if (this.formGroup.value.hasOwnProperty('fieldLabel')) {
            this.data.fieldLabel = this.formGroup.value.fieldLabel ? this.formGroup.value.fieldLabel : null;
        }
        if (this.formGroup.value.hasOwnProperty('overrideStatus')) {
            this.data.overrideStatus = this.formGroup.value.overrideStatus ? this.formGroup.value.overrideStatus : null;
        }
        if (this.formGroup.value.hasOwnProperty('expirationDate')) {
            this.data.expirationDate = this.formGroup.value.expirationDate ? this.formGroup.value.expirationDate : null;
        }
        if (this.formGroup.value.hasOwnProperty('relevantDate')) {
            this.data.relevantDate = this.formGroup.value.relevantDate ? this.formGroup.value.relevantDate : null;
        }
        if (this.formGroup.value.hasOwnProperty('unicode')) {
            this.data.unicode = this.formGroup.value.unicode ? this.formGroup.value.unicode : null;
        }
        if (this.formGroup.value.hasOwnProperty('alwaysRefresh')) {
            this.data.alwaysRefresh = this.formGroup.value.alwaysRefresh ? this.formGroup.value.alwaysRefresh : null;
        }
        if (this.formGroup.value.hasOwnProperty('changeMessage')) {
            this.data.changeMessage = this.formGroup.value.changeMessage ? this.formGroup.value.changeMessage : null;
        }
        if (this.formGroup.value.hasOwnProperty('fieldAlignment')) {
            this.data.fieldAlignment = this.formGroup.value.fieldAlignment ? this.formGroup.value.fieldAlignment : null;
        }
        if (this.formGroup.value.hasOwnProperty('createDate')) {
            this.data.createDate = this.formGroup.value.createDate ? this.formGroup.value.createDate : null;
        }
        if (this.formGroup.value.hasOwnProperty('lastUpdateDate')) {
            this.data.lastUpdateDate = this.formGroup.value.lastUpdateDate ? this.formGroup.value.lastUpdateDate : null;
        }
        if (this.formGroup.value.hasOwnProperty('deleteDate')) {
            this.data.deleteDate = this.formGroup.value.deleteDate ? this.formGroup.value.deleteDate : null;
        }
        if (this.formGroup.value.hasOwnProperty('hiddenFromDistribUrl')) {
            this.data.hiddenFromDistribUrl = this.formGroup.value.hiddenFromDistribUrl ? this.formGroup.value.hiddenFromDistribUrl : null;
        }
        if (this.formGroup.value.hasOwnProperty('fieldLoc')) {
            this.data.fieldLoc = this.formGroup.value.fieldLoc ? this.formGroup.value.fieldLoc : null;
        }
        if (this.formGroup.value.hasOwnProperty('barCodeType')) {
            this.data.barCodeType = this.formGroup.value.barCodeType ? this.formGroup.value.barCodeType : null;
        }
        if (this.formGroup.value.hasOwnProperty('imageUID')) {
            this.data.imageUID = this.formGroup.value.imageUID ? this.formGroup.value.imageUID : null;
        }
        if (this.formGroup.value.hasOwnProperty('connectKey')) {
            this.data.connectKey = this.formGroup.value.connectKey ? this.formGroup.value.connectKey : null;
        }
        if (this.formGroup.value.hasOwnProperty('extResourceUriVariableName')) {
            this.data.extResourceUriVariableName = this.formGroup.value.extResourceUriVariableName ? this.formGroup.value.extResourceUriVariableName : null;
        }
        if (this.formGroup.value.hasOwnProperty('mailtoTemplateID')) {
            this.data.mailtoTemplateID = this.formGroup.value.mailtoTemplateID ? Number(this.formGroup.value.mailtoTemplateID) : 0;
        }
        if (this.formGroup.value.hasOwnProperty('shortLink')) {
            this.data.shortLink = this.formGroup.value.shortLink ? this.formGroup.value.shortLink : null;
        }
        if (this.formGroup.value.hasOwnProperty('altShortLink')) {
            this.data.altShortLink = this.formGroup.value.altShortLink ? this.formGroup.value.altShortLink : null;
        }
        if (this.formGroup.value.hasOwnProperty('mergeField')) {
            this.data.mergeField = this.formGroup.value.mergeField ? this.formGroup.value.mergeField : null;
        }
        if (this.formGroup.value.hasOwnProperty('offerlink')) {
            this.data.offerlink = this.formGroup.value.offerlink ? this.formGroup.value.offerlink : null;
        }
        if (this.formGroup.value.hasOwnProperty('linkCategoryCode')) {
            this.data.linkCategoryCode = this.formGroup.value.linkCategoryCode && this.formGroup.value.linkCategoryCode != '[null]'
                 ? this.formGroup.value.linkCategoryCode : null;
        }
        if (this.formGroup.value.hasOwnProperty('altOfferlink')) {
            this.data.altOfferlink = this.formGroup.value.altOfferlink ? this.formGroup.value.altOfferlink : null;
        }
        if (this.formGroup.value.hasOwnProperty('fieldFormat')) {
            this.data.fieldFormat = this.formGroup.value.fieldFormat ? this.formGroup.value.fieldFormat : null;
        }
        if (this.formGroup.value.hasOwnProperty('altFieldValue')) {
            this.data.altFieldValue = this.formGroup.value.altFieldValue ? this.formGroup.value.altFieldValue : null;
            if (this.formGroup.value.hasOwnProperty('altFieldValueBeta')) {
                if (!this.formGroup.value.altFieldValue && this.formGroup.value.altFieldValueBeta) {
                    this.data.altFieldValue = this.formGroup.value.altFieldValueBeta;
                }
            }
        }
        if (this.formGroup.value.hasOwnProperty('maxDisplayedMessages')) {
            this.data.maxDisplayedMessages = this.formGroup.value.maxDisplayedMessages ? Number(this.formGroup.value.maxDisplayedMessages) : 0;
        }
        if (this.formGroup.value.hasOwnProperty('fieldVisibilityExpression')) {
            this.data.fieldVisibilityExpression = this.formGroup.value.fieldVisibilityExpression ? this.formGroup.value.fieldVisibilityExpression : null;
        }
        if (this.formGroup.value.hasOwnProperty('varTransformationExpression')) {
            this.data.varTransformationExpression = this.formGroup.value.varTransformationExpression ? this.formGroup.value.varTransformationExpression : null;
        }
        if (this.formGroup.value.hasOwnProperty('triggerName')) {
            this.data.triggerName = this.formGroup.value.triggerName ? this.formGroup.value.triggerName : null;
        }
        if (this.formGroup.value.hasOwnProperty('actionName')) {
            this.data.actionName = this.formGroup.value.actionName ? this.formGroup.value.actionName : null;
        }
        if (this.formGroup.value.hasOwnProperty('positionSpec')) {
            this.data.positionSpec = this.formGroup.value.positionSpec ? this.formGroup.value.positionSpec : null;
        }
        return this.data;
    }

}


