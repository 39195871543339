<h2>Summary Reports</h2> 

    <!--  S E L E C T I O N   C R I T E R I A  -->

<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>


<div class="col-7">

    <div class="div-criteria-two">

        <h2 style="width: 15em; color: darkblue; margin-left: 2em;">Summary Reports</h2>

        <div class="grid">

            <div class="col-6">
                <div class="field grid">
                    <div class="col-1"></div>
                    <label for="reportType" class="col-fixed label" style="width: 10em">Report Type: </label>
                    <div class="col-4">
                        <p-dropdown id ="reportType" [options]="reportTypes" [(ngModel)]="selectedReportType" (onChange)="onChangeReportType($event)"
                            [filter]="false" [editable]="false">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="field grid">
                    <div class="col-1"></div>
                    <label class="col-fixed label" style="width: 10em">Selected Brand: </label>
                    <div class="col-6">
                        <label style="color:black">{{selectedBrandCode}}</label>
                        <label style="color:black">{{selectedBrandName}}</label>
                    </div>
                </div>         
            </div>
                
        </div>

        <div class="grid">

            <div class="col-6">
                <div class="field grid">
                    <div class="col-1"></div>
                    <label for="emailAddress" class="col-fixed label" style="width: 10em">Receiver's Email: </label>
                    <div class="col-7">
                        <textarea [rows]="1" [cols]="60" pInputTextarea [autoResize]="autoResize" id="emailAddress" 
                            [(ngModel)]="emailAddress" style="width: 100%;">
                        </textarea>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="field grid">
                    <div class="col-1"></div>
                    <label class="col-fixed label" style="width: 10em">Brand's Timezone: </label>
                    <div class="col-6">
                        <label style="color:black">{{brandTimeZone}}</label>
                    </div>
                </div>         
            </div>
                
        </div>

        <div class="grid">
            <!-- <div class="col-8" style="background-color: #7b95a3; text-align: left;">  -->
        
                    <div class="col-2 group">
                    </div> 
                    <div class="col-6">
    
                        <p-button type="button" label="Choose Brand" 
                            styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onChooseBrandClick($event)">
                        </p-button>
                            <p-button type="button" label="Run Report" 
                            styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)">
                        </p-button>
                        <p-button type="button" label="Clear" 
                            styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)">
                        </p-button>
    
                    </div> 
        
            <!-- </div> -->
        </div>         

    </div>

    <!-- <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
    </div> -->

</div>


<!-- 
<div class="w-auto">

    <div class="grid">
        <div class="col-8" style="background-color: #7b95a3; text-align: left;"> 

            <div class="ui-g-2 group">
                <label for="reportType">Report Type: </label>
            </div> 
            <div class="ui-g-4 group">
                <p-dropdown id ="reportType" [options]="reportTypes" [(ngModel)]="selectedReportType" (onChange)="onChangeReportType($event)"
                    [filter]="false" [editable]="false">
                </p-dropdown>
            </div> 
    
            <div class="ui-g-2 group">
                <label>Selected Brand: </label>
            </div> 
            <div class="ui-g-4 group">
                <label style="color:black">{{brandTimeZone}}</label>
            </div> 

        </div>

    </div> 

    <div class="grid">
        <div class="col-8" style="background-color: #7b95a3; text-align: left;"> 
    
                <div class="ui-g-2 group">
                    <label for="emailAddress">Receiver's Email: </label>
                </div> 
                <div class="ui-g-4 group">
                    <textarea [rows]="1" [cols]="60" pInputTextarea [autoResize]="autoResize" id="emailAddress" 
                        [(ngModel)]="emailAddress" style="width: 100%;"></textarea>
                </div> 
    
                <div class="ui-g-2 group">
                    <label>Brand's Timezone: </label>
                </div> 
                <div class="ui-g-4 group">
                    <label style="color:black">{{brandTimeZone}}</label>
                </div> 
            </div>
    </div> 
    

    <div class="grid">
        <div class="col-8" style="background-color: #7b95a3; text-align: left;"> 

            <div class="ui-g-2 group" [hidden]="customDatesHidden">
                <label for="dateFrom">Date From: </label>
            </div> 
            <div class="ui-g-4 group" [hidden]="customDatesHidden">
                <p-calendar id="dateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" placeholder="Date From:" 
                [defaultDate]="defaultDate" [showIcon]="true" class="margin-left"></p-calendar>
            </div> 

            <div class="ui-g-2 group" [hidden]="customDatesHidden">
                <label for="dateTo" >Date To: </label>
            </div> 
            <div class="ui-g-4 group" [hidden]="customDatesHidden">
                <p-calendar id="dateTo" [(ngModel)]="selectedDateTo" dateFormat="yy-mm-dd" placeholder="Date To:" 
                [showIcon]="true" class="margin-left"></p-calendar>
            </div> 

        </div>
    </div> 


    <div class="grid">
        <div class="col-8" style="background-color: #7b95a3; text-align: left;"> 
    
                <div class="col-2 group">
                </div> 
                <div class="col-4">

                    <button pButton type="button" label="Choose Brand" class="ui-button-warning margin-left" (click)="onChooseBrandClick($event)"></button>
                    <button pButton type="button" label="Run Report" class="ui-button-success margin-left" (click)="onSearchClick($event)"></button>
                    <button pButton type="button" label="Clear" class="ui-button-danger margin-left" (click)="onClearClick($event)"></button>


                </div> 
    
        </div>
    </div> 
    
</div>
 -->

<p-confirmDialog  class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>



