<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded" >
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 

<!--   -->

<div class="col-8"> 

    <p-card header="Brand Profile" subheader="" styleClass="ui-card-light" >

        <form [formGroup]="brandProfileForm" (ngSubmit)="onSubmit(brandProfileForm.value)">

            <div>

                <p-tabView orientation="top" styleClass="ui-card-light">

                    <p-tabPanel header="Brand Specs" styleClass="ui-card-light" >

                        <p-card header="" subheader="" styleClass="ui-card-light">

                            <div class="field grid">
                                  <label for="brandName" class="col-fixed label" style="width: 15em">Brand Name:</label>
                                  <div class="col">
                                    <input pInputText id="brandName" formControlName="brandName" autofocus style="width: 25em"/>
                                  </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="description" class="col-fixed label" style="width: 15em">Brand Description:</label>
                                <div class="col">
                                    <input pInputText id="description" formControlName="description" autofocus style="width: 25em"/>
                                </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="passTypeIdentifier" class="col-fixed label" style="width: 15em">Pass Type Identifier:</label>
                                <div class="col">
                                    <input pInputText id="passTypeIdentifier" formControlName="passTypeIdentifier" autofocus style="width: 25em" readonly="true"/>
                                </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="imageUid" class="col-fixed label" style="width: 15em">Brand Image:</label>
                                <div class="col">
                                    <p-dropdown id ="imageUid" [options]="imageList" formControlName="imageUid"  
                                    (onChange)="onChangeBrandImage($event)" (onBlur)="onBlurBrandImage($event)"
                                    [filter]="false" [editable]="false" pTooltip="Select the brand image" tooltipPosition="top"> 
                                    </p-dropdown>
                                </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="keyName" class="col-fixed label" style="width: 15em">Key Name:</label>
                                <div class="col">
                                    <input pInputText id="keyName" formControlName="keyName" autofocus style="width: 15em" readonly="true"/>
                                </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="nonmobileLogoImageUid" class="col-fixed label" style="width: 15em">Non-mobile Logo Image:</label>
                                <div class="col">
                                    <p-dropdown id ="nonmobileLogoImageUid" [options]="imageList" formControlName="nonmobileLogoImageUid"  
                                    (onChange)="onChangeBrandImage($event)" (onBlur)="onBlurBrandImage($event)"
                                    [filter]="false" [editable]="false" pTooltip="Select the non-mobile logo image" tooltipPosition="top"> 
                                    </p-dropdown>
                                </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="keyFile" class="col-fixed label" style="width: 15em">Key File:</label>
                                <div class="col">
                                    <input pInputText id="keyFile" formControlName="keyFile" autofocus style="width: 15em" readonly="true"/>
                                </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="authToken" class="col-fixed label" style="width: 15em">Auth Token:</label>
                                <div class="col">
                                    <input pInputText id="authToken" formControlName="authToken" autofocus style="width: 25em" readonly="true"/>
                                </div>
                            </div>    
    
                            <div class="field grid">
                                <label for="active" class="col-fixed label" style="width: 15em">Active?:</label>
                                <div class="col">
                                    <!-- <p-checkbox formControlName="active" id="active" [binary]="true"></p-checkbox> -->
                                    <p-tag [value]="isBrandActive ? 'Y' : 'N'" [severity]="getIsBrandActive(isBrandActive)"></p-tag>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                                <div class="col">
                                    <input pInputText id="lastUpdateDate" formControlName="lastUpdateDate" readonly="true" style="width: 15em"/>
                                </div>
                            </div>    
    
                        </p-card>


                    </p-tabPanel>

                    <p-tabPanel header="Client Specs">

                        <p-card header="" subheader="" styleClass="ui-card-light">

                            <div class="field grid">
                                <label for="clientName" class="col-fixed label" style="width: 15em">Client Name:</label>
                                <div class="col">
                                    <input pInputText id="clientName" formControlName="clientName" style="width: 25em"/>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="paymentsPlatform" class="col-fixed label" style="width:15em">Payments Platform:</label>
                                <div class="col">
                                    <p-dropdown id ="paymentsPlatform" [options]="paymentsPlatforms" formControlName="paymentsPlatform"  styleClass="wal-dropdown"
                                        [filter]="false" [editable]="false" [disabled]="isDisabled" pTooltip="Select the payments platform" tooltipPosition="top"> 
                                    </p-dropdown>
                                </div>
                            </div>

                            <div class="field grid">
                                <label for="allowedIPAddresses" class="col-fixed label" style="width: 15em">Allowed IP Addresses:</label>
                                <div class="col">
                                    <textarea [rows]="2" [cols]="100" pInputTextarea id="allowedIPAddresses" 
                                        formControlName="allowedIPAddresses" style="width: 100%;">
                                    </textarea>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="gwIssuerId" class="col-fixed label" style="width: 15em">Google Issuer Id:</label>
                                <div class="col">
                                    <input pInputText id="gwIssuerId" formControlName="gwIssuerId" style="width: 25em"/>
                                </div>
                            </div>    
    

                        </p-card>

                    </p-tabPanel>

                    <p-tabPanel header="Sender">

                        <p-card header="" subheader="" styleClass="ui-card-light">

                            <div class="field grid">
                                <label for="senderName" class="col-fixed label" style="width: 15em">Sender Name:</label>
                                <div class="col">
                                    <input pInputText id="senderName" formControlName="senderName" style="width: 25em"/>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="senderEmail" class="col-fixed label" style="width: 15em">Sender Email:</label>
                                <div class="col">
                                    <input pInputText id="senderEmail" formControlName="senderEmail" style="width: 25em"/>
                                </div>
                            </div>    
                            
                        </p-card>
       
                    </p-tabPanel>

                    <p-tabPanel header="Non-Mobile Config">

                        <p-card header="" subheader="" styleClass="ui-card-light">

                            <div class="field grid">
                                <label for="senderEmail" class="col-fixed label" style="width: 15em">Show Non-Mobile Logo:</label>
                                <div class="col">
                                    <p-checkbox formControlName="showNonMobileLogo" id="showNonMobileLogo" [binary]="true"></p-checkbox>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="nonmobileHeader" class="col-fixed label" style="width: 15em">Non-Mobile Header:</label>
                                <div class="col">
                                    <textarea [rows]="2" [cols]="60" pInputTextarea id="nonmobileHeader" 
                                    formControlName="nonmobileHeader" style="width: 60em" ></textarea>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="showNonMobileEmail" class="col-fixed label" style="width: 15em">Show Non-Mobile Email:</label>
                                <div class="col">
                                    <p-checkbox formControlName="showNonMobileEmail" id="showNonMobileEmail" [binary]="true"></p-checkbox>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="nonmobileEmail" class="col-fixed label" style="width: 15em">Non-Mobile Email:</label>
                                <div class="col">
                                    <textarea [rows]="2" [cols]="60" pInputTextarea id="nonmobileEmail" 
                                    formControlName="nonmobileEmail" style="width: 60em" ></textarea>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="showNonMobileSMS" class="col-fixed label" style="width: 15em">Show Non-Mobile SMS:</label>
                                <div class="col">
                                    <p-checkbox formControlName="showNonMobileSMS" id="showNonMobileSMS" [binary]="true"></p-checkbox>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="nonmobileSMS" class="col-fixed label" style="width: 15em">Non-Mobile SMS:</label>
                                <div class="col">
                                    <textarea [rows]="2" [cols]="60" pInputTextarea id="nonmobileSMS" 
                                    formControlName="nonmobileSMS" style="width: 60em" ></textarea>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="showNonMobileQR" class="col-fixed label" style="width: 15em">Show Non-Mobile QR:</label>
                                <div class="col">
                                    <p-checkbox formControlName="showNonMobileQR" id="showNonMobileQR" [binary]="true"></p-checkbox>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="nonmobileQR" class="col-fixed label" style="width: 15em">Non-Mobile QR:</label>
                                <div class="col">
                                    <textarea [rows]="2" [cols]="60" pInputTextarea id="nonmobileQR" 
                                    formControlName="nonmobileQR" style="width: 60em" ></textarea>
                                </div>
                            </div>    

                            <div class="field grid">
                                <label for="nonmobileFooter" class="col-fixed label" style="width: 15em">Non-Mobile Footer:</label>
                                <div class="col">
                                    <textarea [rows]="2" [cols]="60" pInputTextarea id="nonmobileFooter" 
                                    formControlName="nonmobileFooter" style="width: 60em" ></textarea>
                                </div>
                            </div>    

                        </p-card>

                    </p-tabPanel>

                </p-tabView>                                    

    
    
                <div class="col-12" *ngIf="isSubmitShown">
                    <div  class="col-2" class="row container-fluid"  id="divshow">
                        <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" 
                            [disabled]="!brandProfileForm.valid" *ngIf="isSubmitShown" tooltipPosition="top" pTooltip="Save off changes to the brand profile">
                        </p-button>

                        <p-button type="button" label="Deactivate" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" 
                            (click)="onClickDeactivate($event)"
                            *ngIf="isDeactivateButtonShown" tooltipPosition="top" pTooltip="Make the brand inactive">
                        </p-button>

                        <p-button type="button" label="Activate" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" 
                            (click)="onClickActivate($event)"
                            *ngIf="isActivateButtonShown" tooltipPosition="top" pTooltip="Make the brand active">
                        </p-button>
<!--             
                        <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!brandProfileForm.valid" ></button>
 -->
                    </div> 
                </div>
                           
            </div>
        </form>
    
        <p-footer>
            <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
        </p-footer>
    </p-card>
    
</div>

<div class="col-2" styleClass="ui-card-light"  *ngIf="isImagePreviewShown">
    <img [src]="image" />
</div>     

<div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
    <div class="loader"></div>  
</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-profile"></p-confirmDialog>


